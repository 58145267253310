import CustomNFT from "../pages/CustomNFT";
import ERC1155 from "../pages/ERC1155";
import ERC721 from "../pages/ERC721";
import Home from "../pages/Home";
import NFTMinter from "../pages/NFTMinter";

export const routes = [
    {
        path: "/",
        exact: true,
        component: CustomNFT
    },
    // {
    //     path: "/create",
    //     exact: true,
    //     component: NFTMinter
    // },
    // {
    //     path: "/create/erc721",
    //     exact: true,
    //     component: ERC721
    // },
    // {
    //     path: "/create/erc1155",
    //     exact: true,
    //     component: ERC1155
    // },
    {
        path: "/create/custom",
        exact: true,
        component: CustomNFT
    }
];