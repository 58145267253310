import { Component } from "react";
import Header from "../components/common/Header/Header";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Home extends Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <>
        <Header />
        <div className="container">
          <section>
            <div className="swabox">
              <Link className="btn btn-primary" to="/create/custom">
                Add Token
              </Link>
              <br />
              <br />
              {/* <Link className="btn btn-primary" to="/list">
                List
              </Link> */}
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { userConnection, userAccount, loading, error } = state.connection;

  return {
    userConnection,
    userAccount,
    error,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
