import { Component } from "react";
import { Button } from "react-bootstrap";
import { uploadFile, uploadJson } from "../actions/upload";
import Header from "../components/common/Header/Header";
import Properties from "../components/CustomNFT/Properties";
import { connect } from "react-redux";
import { mintNFT } from "../actions/tokenMinter";
import { JSON_BACKEND, NFT_CONTRACT } from "../config";

class CustomNFT extends Component {
  state = {
    attributes: [],
    loadingJson: false,
    loadingImage: false,
  };

  changePriceType = () => {};

  addProperty = () => {
    let attributes = this.state.attributes.slice();
    attributes.push({});
    this.setState({
      attributes: attributes,
    });
  };

  updateProperty = (id, event) => {
    let attributes = this.state.attributes.slice();
    attributes[id][event.target.name] = event.target.value;

    this.setState({
      attributes: attributes,
    });
  };

  deleteProperty = (id) => {
    let attributes = this.state.attributes.slice();
    attributes.splice(id, 1);

    this.setState({
      attributes: attributes,
    });
  };

  updateInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  uploadData = async () => {
    this.setState({
      loadingJson: true,
    });

    let data = await uploadJson(this.state);

    this.setState({
      loadingJson: false,
    });

    await this.props.mintToken(
      this.props.userConnection,
      Number(data.id),
      data.id,
      this.props.userAccount
    );
  };

  uploadImage = async (event) => {
    this.setState({
      loadingImage: true,
    });
    let data = await uploadFile(event);
    if (data.url) {
      this.setState({
        image: JSON_BACKEND + "backend/" + data.url,
        loadingImage: false,
      });
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="container">
          <br />
          <br />
          <br />
          <br />
          {this.state.image && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={this.state.image} width="100" className="mb-4" />
          )}
          {this.state.loadingImage && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
              width="100"
              className="mb-4"
            />
          )}
          <div>
            <label htmlFor="">Image</label>
            <input
              type="file"
              className="form-control"
              onChange={this.uploadImage}
            />
          </div>
          <br />

          <div>
            <label htmlFor="">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={this.updateInput}
            />
          </div>
          <br />
          <h3>Properties</h3>
          <Properties
            addProperty={this.addProperty}
            properties={this.state.attributes}
            update={this.updateProperty}
            deleteProperty={this.deleteProperty}
          />
          <br />
          <div>
            <label htmlFor="">Description</label>
            <textarea
              type="text"
              className="form-control"
              name="description"
              onChange={this.updateInput}
            ></textarea>
          </div>
          <br />

          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg"
              onClick={this.uploadData}
              disabled={this.props.loading || this.state.loadingJson}
            >
              Submit
            </Button>
          </div>

          {(this.props.loading || this.state.loadingJson) && (
            <div className="mt-4">
              <img
                src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                width="100"
                className="mb-4"
                alt=""
              />
            </div>
          )}
          <h3 className="mt-4">Recent NFT</h3>
          {this.props.tokenId && (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={
                "https://testnets.opensea.io/assets/" +
                NFT_CONTRACT +
                "/" +
                this.props.tokenId
              }
              target="_blank"
              rel="noreferrer"
            >
              Token ID {this.props.tokenId}
            </a>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { tokenId, loading, error } = state.tokenMinter;
  const { userConnection, userAccount } = state.connection;

  return {
    userConnection,
    userAccount,
    tokenId,
    error,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mintToken: (web3, id, uri, account) =>
      dispatch(mintNFT(web3, id, uri, account)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomNFT);
