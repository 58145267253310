// import { CONNECTION_RESULT } from "../config/constants";

import { NFT_MINTED, NFT_MINT_ERROR, NFT_MINT_LOADING } from "../config/constants";

const initialState = {
  loading: false,
  error: false,
  tokenId: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case NFT_MINT_LOADING:
            return {
                ...state,
                loading: true,
                error: false
            }
        case NFT_MINTED:
            return {
                ...state,
                loading: false,
                error: false,
                tokenId: action.tokenId
            }
        case NFT_MINT_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state;
    }
  }