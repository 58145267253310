import { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../components/common/Header/Header";

class NFTMinter extends Component {
  state = {};
  render() {
    return (
      <>
        <Header />
        <div className="container">
          <br />
          <br />
          <br />
          <br />
          <Link className="box" to="/create/erc721">
            ERC721
          </Link>
          <Link className="box" to="/create/erc1155">
            ERC1155
          </Link>
        </div>
      </>
    );
  }
}

export default NFTMinter;
