import { Component } from "react";
import { Button } from "react-bootstrap";

class Properties extends Component {
  state = {};

  render() {
    return (
      <div>
        {this.props.properties.map((property, index) => (
          <div className="row mb-4">
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={(event) => this.props.update(index, event)}
                value={property.name}
              />
            </div>
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                name="value"
                onChange={(event) => this.props.update(index, event)}
                value={property.value}
              />
            </div>
            <div className="col-md-2">
              <Button
                type="danger"
                onClick={() => this.props.deleteProperty(index)}
              >
                X
              </Button>
            </div>
          </div>
        ))}
        <Button onClick={this.props.addProperty}>ADD New Property</Button>
      </div>
    );
  }
}

export default Properties;
