import { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  checkIfAlreadyConnected,
  connectWallet,
} from "../../../actions/connection";
import { connect } from "react-redux";
import { Container, Navbar } from "react-bootstrap";

class Header extends Component {
  state = {};

  componentDidMount() {
    this.props.checkIfAlreadyConnected();
  }

  connect = () => {
    this.props.connectWallet();
  };

  render() {
    return (
      <header>
        <Navbar>
          <Container>
            <Navbar.Brand href="/">JorrParivar</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                {this.props.userConnection ? (
                  <div className="btn btn-primary connect">CONNECTED</div>
                ) : (
                  <div
                    className="btn btn-primary connect"
                    onClick={this.connect}
                  >
                    CONNECT
                  </div>
                )}
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { userConnection, userAccount, loading, error } = state.connection;

  return {
    userConnection,
    userAccount,
    error,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkIfAlreadyConnected: () => dispatch(checkIfAlreadyConnected()),
    connectWallet: () => dispatch(connectWallet()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
