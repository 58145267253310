import NFT from "../abis/NFT.json";
import { NFT_CONTRACT } from "../config";
import { NFT_MINTED, NFT_MINT_ERROR, NFT_MINT_LOADING } from "../config/constants";

export const mintNFT = (web3, id, data, account) => async dispatch =>  {
    try {
        dispatch({type: NFT_MINT_LOADING});
        let contract = new web3.eth.Contract(NFT, NFT_CONTRACT);
        
        const tid = await contract.methods.mint(id, data+ "").send({from: account});

        dispatch({type: NFT_MINTED, tokenId: tid.events.Transfer.returnValues.tokenId});
    } catch (error) {
        dispatch({type: NFT_MINT_ERROR});
        console.error(error);
    }
}