import { Component } from "react";
import { Button } from "react-bootstrap";
import Header from "../components/common/Header/Header";

class ERC721 extends Component {
  state = {
    priceType: 1,
  };

  changePriceType = (id) => {
    this.setState({
      priceType: id,
    });
  };

  render() {
    const priceType = this.state.priceType;
    return (
      <>
        <Header />
        <div className="container">
          <br />
          <br />
          <br />
          <br />
          <div>
            <label htmlFor="">Image</label>
            <input type="file" className="form-control" />
          </div>
          <br />
          <div className="prices">
            <div
              className={"price " + (priceType === 1 && "active")}
              onClick={() => this.changePriceType(1)}
            >
              Fixed
            </div>
            <div
              className={"price " + (priceType === 2 && "active")}
              onClick={() => this.changePriceType(2)}
            >
              Auction
            </div>
            <div
              className={"price " + (priceType === 3 && "active")}
              onClick={() => this.changePriceType(3)}
            >
              Bids
            </div>
          </div>
          <br />
          {priceType === 1 && (
            <div>
              <div>
                <label htmlFor="">Price</label>
                <input type="text" className="form-control" />
              </div>
              <br />
            </div>
          )}
          {priceType === 2 && (
            <div>
              <div>
                <div>
                  <label htmlFor="">Minimum Price</label>
                  <input type="text" className="form-control" />
                </div>
                <br />
              </div>
            </div>
          )}

          <div>
            <label htmlFor="">Name</label>
            <input type="text" className="form-control" />
          </div>
          <br />
          <div>
            <label htmlFor="">Description</label>
            <textarea type="text" className="form-control"></textarea>
          </div>
          <br />
          <div>
            <label htmlFor="">Royality</label>
            <input type="text" className="form-control" />
          </div>
          <br />
          <div className="d-grid gap-2">
            <Button variant="primary" size="lg">
              Submit
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default ERC721;
