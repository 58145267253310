import { combineReducers } from "redux";
import connection from "./connection";
import tokenMinter from "./tokenMinter";

// Aggreating all the reducers defined 
const rootReducer = combineReducers({
    connection: connection,
    tokenMinter: tokenMinter
});

export default rootReducer;