import { JSON_BACKEND } from "../config";

export const uploadJson = async(data) =>  {
    console.log(data);
    const result = await fetch(JSON_BACKEND + "backend/index.php", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    const json = await result.json();
    return json;
}

export const uploadFile = async(event) => {
    const data = new FormData();
    data.append('image',event.target.files[0]);
    const result = await fetch(JSON_BACKEND +"backend/image.php", {
             method: 'POST',
             headers: {
                 'Accept': 'application/json',
             },
             body: data
        });
    const json = await result.json();
    return json;
}